import { signIn, useSession } from "next-auth/client";

import { useRouter } from "next/router";

export default function Login() {
  const [session, loading] = useSession();

  const router = useRouter();

  if (loading) {
    return null;
  }

  if (session) {
    router.push("/");
    return null;
  }

  signIn("tx-data-platform");
  return null;
}
